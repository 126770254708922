.journey .swipe-forward{
  animation: journey-swipe-forward .6s 1;
}

.journey .swipe-backward{
  animation: journey-swipe-backward .6s 1;
}

.journey .no-swipe-forward{
  animation: journey-no-swipe-forward .6s 1;
}

.journey .no-swipe-backward{
  animation: journey-no-swipe-background .6s 1;
}

@keyframes journey-swipe-forward {
  0%{transform: translateX(0);}
  50%{transform: translateX(-100%); opacity: 0}
  60%{transform: translateX(0); opacity: 0}
  100%{opacity: 1;}
}

@keyframes journey-swipe-backward {
  0%{transform: translateX(0);}
  50%{transform: translateX(100%); opacity: 0}
  60%{transform: translateX(0); opacity: 0}
  100%{opacity: 1;}
}

@keyframes journey-no-swipe-forward {
  0%{transform: translateX(0);}
  50%{transform: translateX(-50%);}
  100%{transform: translateX(0);}
}

@keyframes journey-no-swipe-background {
  0%{transform: translateX(0);}
  50%{transform: translateX(50%);}
  100%{transform: translateX(0);}
}
