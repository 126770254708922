.rte {
  margin-bottom: 15px;
}
.rte .megadraft {
  border: 1px solid #ddd;
  padding: 5px;
}
.rte .draft-js-editor {
  display: flex;
  flex-wrap: wrap;
}
.rte .DraftEditor-root {
  min-height: 100px;
  width: 100%;
}
.rte.readOnly .megadraft {
  border: none;
  padding: 0;
}
.rte.readOnly .DraftEditor-root {
  min-height: auto;
}
.rte .toolbar {
  background: white;
  display: flex;
  order: -1;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  padding: 5px;
  position: static;
  margin-bottom: 5px;
  text-align: center;
  border-bottom: 1px solid #f4f4f4;
  color: #888;
}
.rte .toolbar .secondary {
  width: 100%;
}
.rte .toolbar .secondary input {
  width: 50%;
  border: 1px solid transparent;
}
.rte .toolbar .secondary input::placeholder {
  color: #ddd;
}
.rte .toolbar .secondary input:focus {
  outline: none;
  border-bottom: 1px solid #ddd;
}
.rte .toolbar .separator {
  display: inline-block;
  background: #ddd;
  width: 1px;
  height: 30px;
  line-height: 30px;
  margin: 0 10px;
}
.rte .toolbar .flex {
  flex: 1;
}
.rte .toolbar button {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 4px;
  margin: 0 5px;
  font-size: 15px;
  border: none;
  padding:0;
  color: #888;
}
.rte .toolbar button.disabled {
  opacity: 0.2;
  pointer-events: none;
}
.rte .toolbar button.active,
.rte .toolbar button.active:hover {
  background: #444;
  color: #fafafa;
}
.rte .toolbar button:hover {
  color: #444;
  text-decoration: none;
  background: #f4f4f4;
}
