.DraftEditor-root .public-DraftEditorPlaceholder-inner {
  color: #aaaaaa;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  right: 0;
}

.DraftEditor-root figure {
  margin: 0;
}
